import React from 'react';

import styles from './index.module.sass';

interface ClearFiltersButtonProps {
  onClick: () => void;
}

export const ClearFiltersButton = ({ onClick }: ClearFiltersButtonProps ) => (
  <button
    type="button"
    className={`d-none ${styles.button} btn btn-secondary mt-2 px-9`}
    onClick={onClick}
  >
    Clear Filters
  </button>
);
