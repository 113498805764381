import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';

import s from './index.module.sass';

interface IProps {
  rankingFactor: { [key: string]: number | null };
  displayRankingFactorOverlay: boolean;
  onToggleRankingFactorOverlay: () => void;
}

const RankingFactorOverlay: FC<IProps> = ({
  rankingFactor,
  displayRankingFactorOverlay,
  onToggleRankingFactorOverlay,
}) => {
  const ref = useRef<HTMLDivElement>( null );
  const { nps, rankDifference, ...rest } = rankingFactor;
  const margin = rest?.margin;
  const [ overlayHeight, setOverlayHeight ] = useState( 0 );

  useEffect(() => {
    const resizeOverlay = () => {
      const rect = ref.current?.getBoundingClientRect();

      setOverlayHeight((( rect?.width || 0 ) * 5 ) / 4 );
    };

    resizeOverlay();
    window.addEventListener( 'resize', () => resizeOverlay());

    return () => {
      window.removeEventListener( 'resize', () => resizeOverlay());
    };
  }, []);

  useEffect(() => {
    if ( displayRankingFactorOverlay ) {
      const rect = ref.current?.getBoundingClientRect();
      setOverlayHeight((( rect?.width || 0 ) * 5 ) / 4 );
    } else {
      setOverlayHeight( 72 );
    }
  }, [ displayRankingFactorOverlay ]);

  const RankingFactorBadge: FC<{ label: string; value: string }> = ({ label, value }) => (
    <div
      className={classNames(
        'border-0 fs-3 bg-white px-2 d-flex align-items-center',
        s.rankingFactorBadge
      )}
    >
      {label}: {value}
    </div>
  );

  return (
    <div
      ref={ref}
      style={{ height: overlayHeight }}
      className={classNames(
        s.overlay,
        'position-absolute p-2 w-100',
        displayRankingFactorOverlay ? 'bg-dark' : 'bg-transparent'
      )}
    >
      <div className="d-flex align-items-start justify-content-between w-100">
        <div className={classNames( s.positionChange, 'd-inline-flex py-1 px-2 mb-1' )}>
          {rankDifference === 0 || !rankDifference ? (
            <button
              type="button"
              className={classNames( 'border-0 bg-white px-2 py-0', s.overlayTrigger )}
              onClick={onToggleRankingFactorOverlay}
            >
              --
            </button>
          ) : (
            <button
              type="button"
              className={classNames(
                'd-flex px-2 py-0 border-0 bg-white',
                rankDifference > 0 ? 'text-success' : 'text-danger',
                s.overlayTrigger
              )}
              onClick={onToggleRankingFactorOverlay}
            >
              <FontAwesomeIcon
                icon={rankDifference > 0 ? faArrowUp : faArrowDown}
                className="pr-2"
              />
              {Math.abs( rankDifference )}
            </button>
          )}
        </div>
        <div className="d-flex flex-column align-items-end w-100 mb-2">
          {nps && <RankingFactorBadge label="NPS" value={String( nps )} />}
          {margin && <RankingFactorBadge label="Margin" value={String( margin )} />}
        </div>
      </div>
      {displayRankingFactorOverlay &&
        ( Object.keys( rest ).length > 0 ? (
          Object.entries( rest ).map(([ key, value ]) => (
            <div
              key={key}
              className={classNames(
                'd-flex justify-content-between align-items-center',
                value === null ? 'text-muted' : 'text-white'
              )}
            >
              <div>{startCase( key )}</div>

              <pre
                className={classNames( 'text-right font-weight-bold', {
                  'text-danger': value < 0,
                  'text-white': value > 0,
                  'text-muted': value === null || value === 0,
                })}
              >
                {/* FIXME: for some reason ranking factors are coming back as strings from BE */}
                {value === null ? '--' : parseFloat( `${value}` ).toFixed( 2 )}
              </pre>
            </div>
          ))
        ) : (
          <div className="text-white">RankingFactor data not available</div>
        ))}
    </div>
  );
};

export default RankingFactorOverlay;
