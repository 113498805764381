import React from 'react';

export const Message = () => (
  <>
    <h2 className="mb-1 fs-5 fs-md-9">
      <em>
        <span className="d-md-block mr-1">Aww, Fudge.</span>
        <span>No results!</span>
      </em>
    </h2>

    <p>
      Perhaps there&rsquo;s something else that can satisfy your craving? Just change your filters.
    </p>
  </>
);
