import React from 'react';

import noResults from 'images/search/no-results.svg';

import { ClearFiltersButton } from './elements/ClearFiltersButton';
import { Message } from './elements/Message';

import styles from './index.module.sass';

interface NoFilteredResultsProps {
  onClick: () => void;
}

export const NoFilteredResults = ({ onClick }: NoFilteredResultsProps ) => (
  <div className="row">
    <div className="col-12 col-md-9 col-lg-7 col-xl-6 mt-4 mt-md-6 mb-6">
      <figure className="float-md-left mb-3 mr-md-4 ml-md-7 text-center">
        <img className={styles.image} src={noResults} alt="No Results" />
      </figure>

      <div className="text-center text-md-left">
        <Message />

        <ClearFiltersButton onClick={onClick} />
      </div>
    </div>
  </div>
);
