/*
 * TODO: this is a temporary composition until we do more refactoring on
 *       FilteredSearch.
 */
import React, { ReactNode, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { fromPairs, uniq } from 'lodash-es';

import { Deserializer } from 'src/explore/services/api';
import {
  FilteredSearch,
  IAPIFilters,
  ISearchParams,
} from 'src/explore/compositions/FilteredSearch';
import { NoFilteredResults } from 'src/explore/elements/NoFilteredResults';
import ProductGrid from 'src/explore/compositions/ProductGrid';
import ProductSkeletons from 'src/explore/elements/ProductSkeletons';
import RankingFactorOverlay from 'src/explore/elements/RankingFactorOverlay';
import { IProduct, IPromotion, ITrackingParams } from 'src/explore/types/shoppe';
import { ISearchFilter } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/types'; // FIXME: import
import type { ISource } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter'; // FIXME: import
import withLazyLoadSkeleton from 'src/explore/hocs/withLazyLoadSkeleton';

import { SearchTracking } from 'src/explore/services/SearchTracking';

const LazyGrid = withLazyLoadSkeleton( ProductGrid, ProductSkeletons );

interface Props {
  adjustQueryParams?: ( queryParams: ISearchParams ) => ISearchParams;
  apiVersion?: 'v2' | 'v3';
  availableFilters?: ISearchFilter[];
  availableSorting?: Array<string | null>;
  cacheable?: boolean;
  children?: ReactNode;
  clickRef?: string | null;
  conditions?: IAPIFilters;
  defaultSortOrder?: string;
  experimentName?: string;
  hideLove?: boolean;
  hideMerchant?: boolean;
  parseCollectionData?: ( collection: IProduct[], page: number ) => Array<IProduct | IPromotion>;
  size?: 'small';
  source?: ISource;
  title?: string;
  videoMaxPerPage?: number;
  videoPositions?: Array<number>;
  withTvCards?: boolean;
  onRenderHeader?: ({ content, filters }: { content: IProduct[]; filters: IAPIFilters }) => void;
}

const FilteredProductSearch = ({
  adjustQueryParams,
  apiVersion = 'v2',
  availableFilters,
  availableSorting,
  cacheable,
  children,
  clickRef,
  conditions,
  defaultSortOrder,
  experimentName,
  hideLove,
  hideMerchant,
  parseCollectionData,
  size,
  source,
  title,
  onRenderHeader,
}: Props ) => {
  const [ displayRankingFactorOverlay, setDisplayRankingFactorOverlay ] = useState( false );
  const [ countShown, setCountShown ] = useState<{
    products: number;
  }>({ products: 0 });

  const handleSyncTrackingDataToPage = ( data: ITrackingParams, page: IProduct[]) =>
    SearchTracking.syncProductAndVideoDataToPage( data, page );

  const handleQueryParamsAdjustment = ( queryParams: ISearchParams ) => {
    const adjustment = queryParams;
    const currentCountShown = queryParams.page === 1 ? 0 : countShown.products;

    adjustment.product_offset = currentCountShown;
    adjustment.video_offset = 0;
    adjustment.max_num_videos = 0;

    if ( adjustQueryParams ) {
      return adjustQueryParams( adjustment );
    }

    return adjustment;
  };

  const handleParseCollectionData: (
    collection: IProduct[],
    currentPage: number
  ) => Array<IProduct | IPromotion> = ( collection, currentPage ) => {
    if ( currentPage === 1 ) {
      setCountShown({
        products: collection.length,
      });
    } else {
      setCountShown(( currentCount ) => ({
        products: currentCount.products + collection.length,
      }));
    }

    if ( parseCollectionData ) {
      return parseCollectionData( collection, currentPage );
    }

    return collection;
  };

  return (
    <FilteredSearch<IProduct>
      adjustQueryParams={handleQueryParamsAdjustment}
      apiPath="search"
      apiPathOverride={apiVersion === 'v3' ? '/api/v3/' : ''}
      apiResponseParser={Deserializer.deserialize}
      availableFilters={availableFilters}
      availableSorting={availableSorting}
      cacheable={cacheable}
      conditions={conditions}
      defaultSortOrder={defaultSortOrder}
      experimentName={experimentName}
      loadMore="Show More Products"
      pageSize={40} // 20, 14, 10 rows on small, large, XL size respectively
      parseCollectionData={handleParseCollectionData}
      source={source}
      syncTrackingDataToPage={handleSyncTrackingDataToPage}
      syncUrl
      title={title}
      onRenderContent={({ data, filterCount, loading, meta, onResetFilters }) => {
        const rankingFactorTemplate = meta?.ranking_factors
          ? camelcaseKeys(
              fromPairs(
                uniq(
                  Object.values( meta.ranking_factors )
                    .map(( x ) => Object.keys( x ))
                    .flat( 2 )
                    .sort(( a, b ) => a.localeCompare( b ))
                ).map(( x ) => [ x, null ])
              )
            )
          : {};

        return (
          <LazyGrid
            across={4}
            clickRef={clickRef}
            hideLove={hideLove}
            hideMerchant={hideMerchant}
            pristine={loading}
            products={data}
            searchId={meta?.search_id}
            size={size}
            onBellowProductContentRender={( product: IProduct ) => {
              if ( !meta.ranking_factors ) return null;
              const rankingFactor = {
                ...rankingFactorTemplate,
                ...camelcaseKeys( meta.ranking_factors[product.id] || {}, { deep: true }),
                nps: product.merchant.nps,
              };

              return (
                <RankingFactorOverlay
                  displayRankingFactorOverlay={displayRankingFactorOverlay}
                  rankingFactor={rankingFactor}
                  onToggleRankingFactorOverlay={() => setDisplayRankingFactorOverlay(( x ) => !x )}
                />
              );
            }}
          >
            {filterCount > 0 ? <NoFilteredResults onClick={onResetFilters} /> : null}
          </LazyGrid>
        );
      }}
      onRenderHeader={onRenderHeader}
    >
      {children}
    </FilteredSearch>
  );
};

export type { IAPIFilters, ISearchFilter };
export { FilteredProductSearch };
